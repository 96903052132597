import { fetch } from "../axios.service";
import {
  BASE_URL,
  TRADING_BASE_URL,
  WALLET_BASE_URL,
  ORDERS_BASE_URL,
} from "../../Constants/constant";

export const login = (data) => {
  return fetch("post", `${BASE_URL}/socket/create_admin`, data);
};

export const get2fastatusApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/get2fastatus`, {}, data);
};

export const google2faAuthenticateApi = (data, options) => {
  return fetch(
    "post",
    `${BASE_URL}/admin/google2faAuthenticate`,
    data,
    options
  );
};

export const deviceAuthenticateApi = (data) => {
  return fetch("post", `${BASE_URL}/user/authneticateDevice`, data);
};

export const getUsersApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/user/list`, data, options);
};

export const getActiveUsersApi = (options) => {
  return fetch("get", `${ORDERS_BASE_URL}/getActiveUsers`, {}, options);
};

export const userActionApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/user/user-action`, data, options);
};
export const emailVerifiedServiceApi = (data, options) => {
  return fetch(
    "post",
    `${BASE_URL}/admin/sendVerificationEmail`,
    data,
    options
  );
};

export const sendAuthKeyApi = (user_id, options) => {
  return fetch(
    "get",
    `${BASE_URL}/admin/user/send-auth-key/${user_id}`,
    {},
    options
  );
};

export const singleTraderInfoApi = (traderId, options) => {
  return fetch("post", `${BASE_URL}/admin/kyc/view/${traderId}`, {}, options);
};

export const usersWithdrawTransApi = (data, options) => {
  return fetch(
    "get",
    `${WALLET_BASE_URL}/${data.currency}/admin/users_withdraw_transactions/${data.page}/${data.traderID}`,
    {},
    options
  );
};

export const downlineApi = (user_id, options) => {
  return fetch(
    "get",
    `${BASE_URL}/admin/refferal/downline/${user_id}`,
    {},
    options
  );
};

export const statisticsApi = (user_id, options) => {
  return fetch(
    "get",
    `${BASE_URL}/admin/refferal/get_statistics/${user_id}`,
    {},
    options
  );
};

export const userBalanceApi = (user_id, options) => {
  return fetch(
    "get",
    `${WALLET_BASE_URL}/admin/get-user-bal/${user_id}`,
    {},
    options
  );
};

export const filterUsersApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/user/list/search`, data, options);
};

export const addNotesApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/user/notes/add`, data, options);
};

export const saveCountries = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/add/countries`, data, options);
};

export const getCountries = (options) => {
  return fetch("get", `${BASE_URL}/admin/countries/list`, {}, options);
};

export const submittedKycApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/kyc/list`, data, options);
};

export const singleKycDataApi = (userId, options) => {
  return fetch("post", `${BASE_URL}/admin/kyc/view/${userId}`, {}, options);
};

export const kycActionApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/kyc/action`, data, options);
};

export const kycImage = (data, options) => {
  return fetch("post", `${BASE_URL}/file/image`, data, options);
};

export const getKycCountApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/kyc/getCount`, data, options);
};

export const getKycFileApi = (data, options) => {
  return fetch("post", `${BASE_URL}/file/getFileAdmin`, data, options);
};

export const updateUserDetailsApi = (data, options) => {
  return fetch(
    "put",
    `${BASE_URL}/admin/kyc/update/${data.users_id}
  `,
    data,
    options
  );
};

export const deleteApprovedKycApi = (userId, options) => {
  return fetch("post", `${BASE_URL}/admin/kyc/delete/${userId}`, {}, options);
};

export const getAdminDetails = (options) => {
  return fetch(
    "get",
    `${BASE_URL}/admin/profile
  `,
    {},
    options
  );
};

export const subAdminRole = (options) => {
  return fetch(
    "get",
    `${BASE_URL}/admin/subadmin/roles
  `,
    {},
    options
  );
};

/* export const dashboardApi = (data, options) => {
  return fetch("get", `${BANKING_BASE_URL}/admin/dashboardDataBanking`, data, options);
};
 */
