import { smallestUnit } from "../Constants/constant";
import csc from "country-state-city";

export const getCountryName = (id, countiesList) => {
  let countryVal = "";
  if (!isNaN(id)) {
    let countryName =
      countiesList &&
      countiesList.length > 0 &&
      countiesList.find((item) => item?.id == id);
    countryVal = countryName?.name;
  } else {
    countryVal = id;
  }
  return countryVal;
};

export const getSatetName = (id, stateId, statesList) => {
  let stateVal = "";

  if (!isNaN(id) && !isNaN(stateId)) {
    let currentState =
      statesList &&
      statesList.length > 0 &&
      statesList.find((item) => item?.id == stateId);
    stateVal = currentState ? currentState?.name : "";
  } else {
    stateVal = stateId;
  }
  return stateVal;
};

export const getCityName = (id, cityId, citiesList) => {
  let cityVal = "";
  if (!isNaN(cityId) && !isNaN(id)) {
    let currentCity =
      citiesList &&
      citiesList.length > 0 &&
      citiesList.find((item) => item?.id == cityId);

    cityVal = currentCity?.name;
  } else {
    cityVal = cityId;
  }

  return cityVal;
};

export const convertTZ = (date, tzString) => {
  var data = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
  return data;
};

export const getFormatedCountries = (counties) => {
  let result = [];
  try {
    counties.forEach((item) => {
      result.push({
        key: item.code,
        name: item.name,
        label: item.name,
      });
    });
    return result;
  } catch (err) {
    return result;
  }
};

export const smallestUnitFormat = (amount) => {
  if (amount) {
    return amount / smallestUnit;
  } else {
    return 0;
  }
};

export const listDateFormat = (date) => {
  let newDAte = new Date(date);
  let parsedDate = newDAte.toLocaleDateString("default", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  let time = newDAte.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  return parsedDate + " " + time;
};

export const checkTruthy = (value) => {
  if (!value || value == "undefined" || value == null) {
    return "N/A";
  } else {
    return value;
  }
};
export const setMenu = (value) => {
  var localMenu = [];
  localMenu.push("/auth/dashboard");
  {
    value.includes("manage_subadmin") && localMenu.push("/auth/dashboard");
  }
  {
    value.includes("manage_subadmin") &&
      localMenu.push("/auth/sub-admin-management/sub-admin-users");
  }
  {
    value.includes("traders") && localMenu.push("/auth/traders");
  }
  {
    value.includes("submitted_kyc") && localMenu.push("/auth/submittedKyc");
  }
  {
    value.includes("approved_kyc") && localMenu.push("/auth/approvedKyc");
  }
  {
    value.includes("reject_kyc") && localMenu.push("/auth/rejectedKyc");
  }
  {
    value.includes("withdraw_transaction") &&
      localMenu.push("/auth/withdrawal_transactions");
  }
  {
    value.includes("deposit_transaction") &&
      localMenu.push("/auth/deposite_transaction");
  }
  {
    value.includes("orders_management") && localMenu.push("/auth/order-list");
  }
  {
    value.includes("trade_summary") && localMenu.push("/auth/trade-summary");
  }
  {
    value.includes("currency_manage") &&
      localMenu.push("/auth/currency-manage");
  }

  {
    value.includes("currency_pairs") && localMenu.push("/auth/currency-pairs");
  }
  {
    value.includes("loan_history") && localMenu.push("/auth/loan");
  }
  {
    value.includes("swap_setting") && localMenu.push("/auth/swap-setting");
  }
  {
    value.includes("loan_cash_history") && localMenu.push("/auth/cash");
  }
  {
    value.includes("stable_coin") && localMenu.push("/auth/stablecoin");
  }
  {
    value.includes("Kyc_settings") && localMenu.push("/auth/kycsettings");
  }
  {
    value.includes("country_state_city") &&
      localMenu.push("/auth/country-state-city");
  }
  // value.forEach((e) => {
  //   if (e != "")
  // });
  // debugger;
  localStorage.setItem("localMenu", localMenu);
};

// convert exponential to decimal
export const convertExponentialToDecimalTotal = (exponentialNumber) => {
  if (!exponentialNumber) {
    return false;
  }

  const str = exponentialNumber.toString();

  if (str.indexOf("e") !== -1) {
    const exponent = parseInt(str.split("-")[1], 10);
    // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
    // it will still return the exponential representation
    // So I have to use .toFixed()
    const result = exponentialNumber.toFixed(20);

    let value = result;

    if (value <= 0) {
      value = "0.0";
    } else {
      let firstBlncTest = String(value).split(".", 2);
      if (firstBlncTest.length > 1) {
        if (firstBlncTest[1].length > 8) {
          let presc3 = firstBlncTest[1].substring(0, 8);

          value = firstBlncTest[0] + "." + presc3;

          if (value <= 0) value = "0.0";
        }
      }
    }

    return value;
  } else {
    return exponentialNumber;
  }
};
