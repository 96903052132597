import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  swapHistoryList: [],
};

export default function swapData(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_SWAPHISTORY_LIST:
      return {
        ...state,
        swapHistoryList: action.payload,
      };

    default:
      return state;
  }
}
