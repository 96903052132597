import React, { Component } from "react";
import { connect } from "react-redux";
// import { Container, Spinner } from "react-bootstrap";
import Loader from "react-loader-spinner";

class LoaderComponent extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="overlayDiv">
          <Loader
            type="Circles"
            color="#018764"
            height={80}
            width={80}
            // timeout={3000000} //3 secs
            visible={true}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}


const mapStateToProps = (state) => {
  return {
    loading: state.loader.loading,
  };
};

export default connect(mapStateToProps, null)(LoaderComponent);