//export const API_HOST = process.env.REACT_APP_API_URL;
//export const BANKING_API_URL = process.env.REACT_APP_BANKING_URL;
import dateFormat from "dateformat";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SWAP_BASE_URL = process.env.REACT_APP_SWAP_BASE_URL;

export const TRADING_BASE_URL = process.env.REACT_APP_TRADING_BASE_URL;
export const WALLET_BASE_URL = process.env.REACT_APP_WALLET_BASE_URL;
export const ORDERS_BASE_URL = process.env.REACT_APP_ORDERS_BASE_URL;
export const P2P_BASE_URL = process.env.REACT_APP_P2P_BASE_URL;
export const ENCDECRYPTKEY = process.env.REACT_APP_ENCDECRYPTKEY;

export const SOL_URL = process.env.REACT_APP_SOL_URL;
export const ETH_URL = process.env.REACT_APP_ETH_URL;
export const AVAX_URL = process.env.REACT_APP_AVAX_URL;
export const BTC_URL = process.env.REACT_APP_BTC_URL;
export const BNB_URL = process.env.REACT_APP_BNB_URL;

export const PASSWORD1 = process.env.REACT_APP_PASSWORD_ONE;
export const PASSWORD2 = process.env.REACT_APP_PASSWORD_TWO;
export const PASSWORD3 = process.env.REACT_APP_PASSWORD_THREE;

export const rootName = "";
export const baseUrl = "";
export const BASEURL = "";

export const offset = 0;
export const limit = 10;
export const smallestUnit = 100000000;

export const showTwoDecimal = (amountVal, decimals) => {
  let decimalVal = amountVal;
  let updateVal = amountVal.toString();
  if (updateVal.includes(".")) {
    let getDicimal = updateVal.split(".");
    let result = getDicimal[1].substring(0, decimals);
    decimalVal = getDicimal[0] + "." + result;
  }
  return decimalVal;
};

export const getWalletAddress = () => {
  return localStorage.getItem("wallet_address");
};

export const SUCCESS_MESSAGE = {
  UPDATE: "Status has been updated successfully",
  USER_TYPE_CHANGE: "User Type has been changed successfully",
};
export const PAGELIMIT = [
  { name: "10", value: 10 },
  { name: "20", value: 20 },
  { name: "30", value: 30 },
];
export const PERMISSONS = [
  { name: "orders_management", label: "Orders Management" },
  { name: "users_management", label: "Users Management" },
  { name: "currency_manage", label: "Currency" },
  { name: "currency_pairs", label: "Currency Pair" },
  { name: "manage_subadmin", label: "Manage Subadmin" },
  { name: "traders", label: "Traders" },
  { name: "trade_summary", label: "Trade Summary" },
  { name: "submitted_kyc", label: "Submitted KYC" },
  { name: "approved_kyc", label: "approved_kyc" },
  { name: "reject_kyc", label: "Reject Kyc" },

  { name: "reports", label: "Reports" },
  { name: "loan_history", label: "Loan History" },
  { name: "swap_setting", label: "Swap History" },
  { name: "loan_cash_history", label: "Loan Cash History" },
  { name: "stable_coin", label: "Stable Coin" },
  { name: "withdraw_transaction", label: "Withdraw Transaction" },
  { name: "deposit_transaction", label: "Deposit Transaction" },
  { name: "Kyc_settings", label: "Kyc Settings" },
  { name: "country_state_city", label: "Country List" },
];

export const DEPOSITE_STATUS = [
  { name: "Pending", value: 0 },
  { name: "Unconfirmed", value: 1 },
  { name: "Confirmed", value: 2 },
  { name: "Declined", value: 3 },
];

export const Loan_STATUS = [
  { name: "Standard", value: "standard" },
  { name: "Instant", value: "instant" },
];
export const LOAN_FILTER = [
  { name: "Pending", value: 0 },
  { name: "Approved", value: 1 },
  { name: "Rejected", value: 2 },
];

export const ORDER_STATUS = [
  { name: "All", value: "all" },
  { name: "Open", value: 1 },
  { name: "Cancelled", value: 2 },
  { name: "Completed", value: 3 },
  // { name: "Open", value: 4 },
];

export const SIDE = [
  { name: "Buy", value: 0 },
  { name: "Sell", value: 1 },
  // { name: "Open", value: 4 },
];

export const SUBADMIN_STATUS = [
  { name: "All", value: "" },
  { name: "Active", value: 1 },
  { name: "Deactive", value: 2 },
];

export const ORDER_TYPE = [
  { name: "All", value: "all" },
  { name: "Limit", value: 0 },
  { name: "Market", value: 1 },
];

export const COUNTRY = [{ name: "Afghanistan", code: "AF" }];

export const REVENUE_FILTER = [
  {
    label: "Day",
    value: "Day",
  },
  {
    label: "Week",
    value: "Week",
  },
  {
    label: "Month",
    value: "Month",
  },
  {
    label: "Year",
    value: "Year",
  },
];

export const MONTH_FILTER = [
  {
    label: "1 Months",
    value: "30",
  },
  {
    label: "3 Months",
    value: "90",
  },
  {
    label: "6 Months",
    value: "180",
  },
  {
    label: "9 Months",
    value: "270",
  },
];
// export const WEEK_DAYS = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
// ];

export const WEEK_FILTER = [
  {
    label: "Last Week",
    value: "1",
  },
  {
    label: "2 Week",
    value: "2",
  },
  {
    label: "3 Week",
    value: "3",
  },
  {
    label: "4 Week",
    value: "4",
  },
];

//get name of days from current
export const weekDaysName = () => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  var goBackDays = 7;

  var today = new Date();
  var daysSorted = [];

  for (var i = 0; i < goBackDays; i++) {
    var newDate = new Date(today.setDate(today.getDate() - 1));
    daysSorted.push(days[newDate.getDay()]);
  }
  return daysSorted;
};

//get list of years from current
export const YEAR_FILTER = () => {
  let yearsArr = [];
  let currentYear = new Date().getFullYear();
  let startYear = 1980;
  while (currentYear >= startYear) {
    yearsArr.push(currentYear);
    currentYear--;
  }
  return yearsArr;
};

//change date format UTC

export const convertTZ = (date, tzString) => {
  var data = new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
  return data;
};

//get list of dates in a year
export const getYearDates = (currentYear) => {
  var curr = new Date(); // get current date
  let endDate;
  let stopDate;
  let fullYear = new Date().getFullYear();
  if (currentYear == fullYear) {
    endDate = curr.getDate();
    stopDate = new Date(curr.setDate(endDate));
  } else {
    stopDate = new Date(currentYear, 11, 31);
  }
  var startDate = new Date(currentYear, 0, 1);
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  return getDates(startDate, stopDate);
};

//create list of array start date to end date
export const getDates = (startDate, stopDate) => {
  let dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    let convertDate = dateFormat(currentDate, "mm/dd/yyyy");

    dateArray.push(convertDate);
    let date = new Date(currentDate);
    date.setDate(date.getDate() + 1);
    currentDate = date;
  }
  return dateArray;
};

//get list of month dates from current to past
export const getMonthDates = (days) => {
  var curr = new Date(); // get current date
  let first = curr.getDate(); // First day is the day of the month - the day of the week
  var last = first - days; // last day is the first day + 6
  var stopDate = new Date(curr.setDate(first));
  var startDate = new Date(curr.setDate(last));
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  return getDates(startDate, stopDate);
};

//get list of week dates from current to past
export const getWeekDates = (days) => {
  let daysDate;
  if (days == 1) {
    daysDate = 6 * days;
  } else {
    daysDate = 7 * days - 1;
  }
  var curr = new Date(); // get current date
  let first = curr.getDate(); // First day is the day of the month - the day of the week
  var last = first - daysDate; // last day is the first day + 6

  var stopDate = new Date(curr.setDate(first));
  var startDate = new Date(curr.setDate(last));

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  return getDates(startDate, stopDate);
};

//get current and last day dates
export const getDaysDate = (days) => {
  var curr = new Date(); // get current date
  let first = curr.getDate(); // First day is the day of the month - the day of the week
  var last = first - days; // last day is the first day + 6

  var stopDate = new Date(curr.setDate(first));
  var startDate = new Date(curr.setDate(last));

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  return getDates(startDate, stopDate);
};

// user types:
// default: NORMAL_USER
// BUSINESS_USER

export const getWalletAddressFormate = (data) => {
  return `${data?.substring(0, 4)}...${data?.substring(
    data.length - 4,
    data.length
  )}`;
};

export const dateFormatChange = (date) => {
  const startDate = date;
  const startdateArray = startDate.split("/");
  return `${startdateArray[2]}-${startdateArray[0]}-${startdateArray[1]}`;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
//get list of months from current current year
export const getListOfMonth = (currentYear) => {
  let fullYear = new Date().getFullYear();
  let currentMonth;
  let d;
  let month;
  let today = new Date();
  if (currentYear == fullYear) {
    currentMonth = today.getMonth() + 1;
  } else {
    currentMonth = 12;
  }
  let monthArr = [];
  for (var i = currentMonth; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), currentMonth - i, 1);
    month = monthNames[d.getMonth()];
    monthArr.push(month);
  }
  return monthArr;
};

export const compareMonth = (date) => {
  const d = new Date(date);
  return monthNames[d.getMonth()];
};
