import { fetch } from "../axios.service";
import {
  BASE_URL,
  TRADING_BASE_URL,
  WALLET_BASE_URL,
} from "../../Constants/constant";

export const getSubadminListApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/subadmin/list`, data, options);
  // return fetch("post", `http://10.1.6.214:3000/users/admin/subadmin/list`, data, options);
};

export const deleteSubadmin = (options, id) => {
  return fetch(
    "post",
    `${BASE_URL}/admin/subadmin/deleteSubAdminUser`,
    { id: id },
    options
  );
};

export const getSingleSubadmin = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/subadmin/getUser`, data, options);
};

export const updateSubadminApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/subadmin/update`, data, options);
};

export const addSubadminApi = (data, options) => {
  return fetch("post", `${BASE_URL}/admin/subadmin/add`, data, options);
};
