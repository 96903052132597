import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  getStableCoinData: {},
  saveStableCoinData: {},
  redeemList: {},
  acquireList: {},
};

export default function StableCoin(state = initialState, action) {
  switch (action.type) {
    case types.GET_STABLECOIN_DATA:
      return { ...state, getStableCoinData: action.payload.getstablecoindata };

    case types.SAVE_STABLECOIN_DATA:
      return {
        ...state,
        saveStableCoinData: action.payload.savestablecoindata,
      };
    case types.SAVE_REDEEMLIST:
      return {
        ...state,
        redeemList: action.payload,
      };
    case types.SAVE_ACQUIRELIST:
      return {
        ...state,
        acquireList: action.payload,
      };

    default:
      return state;
  }
}
