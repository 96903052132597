import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { signInFn } from "../../../Redux/Actions/user/user.action";
//import LoaderComponent from '../../components/Loader/loader.component';
import LoginForm from "./loginForm";
import publicIp from "public-ip";

const HomePage = (props) => {
  const [showmodal, setShowModal] = useState(true);
  const [address, setAddress] = useState("");

  const loginFormData = async (values) => {
    let IP = await publicIp.v4();
    if (IP) {
      values.ip = IP;
    } else {
      values.ip = "157.36.101.202";
    }
    props.signInFn(values);
  };

  const [data, setdata] = useState({
    address: "", // Stores address
    Balance: null, // Stores balance
  });

  if (window.ethereum) {
    // Do something
  } else {
    alert("install metamask extension!!");
  }

  const userCallback = (res) => {
    console.log("userCallback", res);
    // dispatch(
    //   resendOtp(
    //     {
    //       username: values.email,
    //       type: emailType,
    //       service: serviceType,
    //     },
    //     callback
    //   )
    // );
  };
  const connectWallet = () => {
    window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
      // Return the address of the wallet
      console.log("address=", res);
      setAddress(res[0]);
    });
  };

  const signInUser = () => {
    console.log("address", address);
    props
      .signInFn({ address: address })
      .then((res) => {
        if (res) {
          localStorage.setItem("admin_uuid", res?.data?.admin_uuid);
          localStorage.setItem("wallet_address", res?.data?.wallet_address);
          if (localStorage.getItem("wallet_address")) {
            window.location.href = `/auth/dashboard`;
          }
        }
        setAddress("");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (address) {
      signInUser();
    }
  }, [address]);
  const walletBalance = () => {
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [data.address, "latest"],
      })
      .then((balance) => {
        // Return string value to convert it into int balance
        console.log("balance=", balance);

        // Yarn add ethers for using ethers utils or
        // npm install ethers
        // console.log(ethers.utils.formatEther(balance));
        // Format the string into main latest balance
      });
  };

  return (
    <div className="Login">
      {/* <LoaderComponent /> */}
      <LoginForm connectWallet={connectWallet} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    //     loading: state.loader.loading,
    //    token: state.user.tokens
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInFn: (data, history) => dispatch(signInFn(data, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
