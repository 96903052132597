import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  countryList: [],
  totalRecords: 0,
  stateList: [],
  totalStateRecords: 0,
  cityList: [],
  totalCityRecords: 0,
};

export default function CountryList(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload.countries,
        totalRecords: action.payload.totalRecords,
      };
    case types.SAVE_STATE_LIST:
      return {
        ...state,
        stateList: action.payload.stateList,
        totalStateRecords: action.payload.totalRecords,
      };
    case types.SAVE_CITY_LIST:
      return {
        ...state,
        cityList: action.payload.cityList,
        totalCityRecords: action.payload.totalRecords,
      };
    default:
      return state;
  }
}
