import React from 'react';
import Routes from './Routes/routes';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default () => {
  return (
    <div>
      <Routes />
    </div>
  );
};
