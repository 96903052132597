import * as types from "../../../Constants/_Types/types.reduxStore";

const initialState = {
  allLoans: [],
  totalRecords: 0,
  allCashLoans: [],
  totalCashRecords: 0,
};

export default function Loans(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_LOANS:
      return {
        ...state,
        allLoans: action.payload.loans,
        totalRecords: action.payload.totalRecords,
      };
    case types.SAVE_CASH_LOANS:
      return {
        ...state,
        allCashLoans: action.payload.loans,
        totalCashRecords: action.payload.totalRecords,
      };
    default:
      return state;
  }
}
