import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../Redux/Actions/user/user.action";
import { DefaultLayout } from "../../Components/layouts";
let allPaths = window.location?.pathname;

const AuthGuard = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem("admin_uuid") ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.tokens,
    userData: state.auth.encryptUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
