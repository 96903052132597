import React, { useEffect, useState } from "react";
import { Container, Row } from "shards-react";
import PageTitle from "../common/PageTitle";
import {
  getRevenueFn,
  getRevenueListFn,
} from "../../Redux/Actions/dashboard/dashboard.action";
import { connect } from "react-redux";
import { Card, Col, FormLabel, Table, Form } from "react-bootstrap";
import "./Dashboard.css";
import RevenueChart from "./RevenueChart";
import { Link } from "react-router-dom";
import {
  compareMonth,
  convertTZ,
  dateFormatChange,
  getDates,
  getDaysDate,
  getListOfMonth,
  getMonthDates,
  getWalletAddress,
  getWeekDates,
  getYearDates,
  offset,
  weekDaysName,
  WEEK_DAYS,
  WEEK_FILTER,
  showTwoDecimal,
} from "../../Constants/constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "rc-datepicker/lib/style.css";
import {
  REVENUE_FILTER,
  MONTH_FILTER,
  YEAR_FILTER,
} from "../../Constants/constant";
import dateFormat from "dateformat";
import { date } from "yup/lib/locale";

// class Dashboard extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       offset: 0,
//       limit: 10,
//       data,
//       volumeData: [],
//     };
//   }

const Dashboard = (props) => {
  const { revenueList } = props;
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const [filterVal, setFilterVal] = useState("Month");
  const [revenuList, setRevenueList] = useState([]);
  const [orderFeeData, setOrderFeeData] = useState([]);
  const [orderFeeDataMaker, setOrderFeeDataMaker] = useState([]);
  const [orderFeeDataTaker, setOrderFeeDataTaker] = useState([]);
  const [referralFeeData, setReferralFeeData] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [weekFilter, setWeekFilter] = useState("1");
  const [revenueDates, setRevenueDates] = useState([]);
  const [monthFilter, setMonthFilter] = useState("30");
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
  const [filterRevenue, setFilterRevenue] = useState("");

  const pageChange = (e, data) => {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    // alert(pageNo)
  };

  let {
    dashboardCounts,
    getUser,
    getActiveUser,
    tradeVolume,
    tradeVolumeAmount,
    tradeByMonth,
    activeCoins,
    totalRecords,
  } = props;
  // const { data: chartData, volumeData } = this.state;

  const dataSet = {
    labels: [""],
    datasets: [
      {
        label: "NEW USERS",
        backgroundColor: "lightgreen",
        borderColor: "lightgreen",
        borderWidth: 2,
        data: [totalRecords],
      },
      {
        label: "ACTIVE USERS",
        backgroundColor: "skyblue",
        borderColor: "skyblue",
        borderWidth: 2,
        // data: [
        //   getActiveUser.data && getActiveUser.data[0]
        //     ? getActiveUser.data[0]?.activeUser
        //     : 0,
        // ],
      },
    ],
  };

  var finalTrade = {};
  for (var i = 0; i < tradeByMonth?.length; i++) {
    for (var j in tradeByMonth[i]) {
      if (!finalTrade[j]) {
        finalTrade[j] = [];
      }
      finalTrade[j].push(tradeByMonth[i][j]);
    }
  }

  const getRevnueListData = (startDate, endDate) => {
    let data = {
      endDate: endDate ? endDate : toDate,
      startDate: startDate ? startDate : fromDate,
      filterVal:
        filterVal == "Week" || filterVal == "Month" ? "Day" : filterVal,
      address: getWalletAddress(),
    };

    props.getRevenueListFn(data).then((res) => {
      setFilterRevenue(res?.totalRevenue);
      if (res?.orderFeeData) {
        let coinData = [];
        if (filterVal == "Month" || filterVal == "Week" || filterVal == "Day") {
          revenueDates &&
            revenueDates.length > 0 &&
            revenueDates.forEach((item) => {
              let resData = res?.orderFeeData.find(
                (element) =>
                  element?.created_at.split("T")[0] == dateFormatChange(item)
              );
              if (resData) {
                coinData.push(resData?.sumOrder.toFixed(6));
              } else {
                coinData.push(0);
              }
            });
          setOrderFeeData(coinData);
        } else if (filterVal == "Year" && yearFilter) {
          let monthsData = [];
          getListOfMonth(yearFilter).forEach((item) => {
            let resData = res?.orderFeeData.find(
              (element) => compareMonth(element?.created_at) == item
            );
            if (resData) {
              monthsData.push(resData?.sumOrder.toFixed(6));
            } else {
              monthsData.push(0);
            }
          });

          setOrderFeeData(monthsData);
        }
      }
      if (res?.orderFeeDataMaker) {
        let dataMaker = [];
        if (filterVal == "Month" || filterVal == "Week" || filterVal == "Day") {
          revenueDates &&
            revenueDates.length > 0 &&
            revenueDates.forEach((item) => {
              let resData = res?.orderFeeDataMaker.find(
                (element) =>
                  element?.created_at.split("T")[0] == dateFormatChange(item)
              );
              if (resData) {
                dataMaker.push(resData?.sumOrder.toFixed(6));
              } else {
                dataMaker.push(0);
              }
            });
          setOrderFeeDataMaker(dataMaker);
        } else if (filterVal == "Year" && yearFilter) {
          let monthsData = [];
          getListOfMonth(yearFilter).forEach((item) => {
            let resData = res?.orderFeeDataMaker.find(
              (element) => compareMonth(element?.created_at) == item
            );
            if (resData) {
              monthsData.push(resData?.sumOrder.toFixed(6));
            } else {
              monthsData.push(0);
            }
          });

          setOrderFeeDataMaker(monthsData);
        }
      }
      if (res?.orderFeeDataTaker) {
        let dataTaker = [];
        if (filterVal == "Month" || filterVal == "Week" || filterVal == "Day") {
          revenueDates &&
            revenueDates.length > 0 &&
            revenueDates.forEach((item) => {
              let resData = res?.orderFeeDataTaker.find(
                (element) =>
                  element?.created_at.split("T")[0] == dateFormatChange(item)
              );
              if (resData) {
                dataTaker.push(resData?.sumOrder.toFixed(6));
              } else {
                dataTaker.push(0);
              }
            });
          setOrderFeeDataTaker(dataTaker);
        } else if (filterVal == "Year" && yearFilter) {
          let monthsData = [];
          getListOfMonth(yearFilter).forEach((item) => {
            let resData = res?.orderFeeDataTaker.find(
              (element) => compareMonth(element?.created_at) == item
            );
            if (resData) {
              monthsData.push(resData?.sumOrder.toFixed(6));
            } else {
              monthsData.push(0);
            }
          });

          setOrderFeeDataTaker(monthsData);
        }
      }

      if (res?.referralFeeData) {
        let referral = [];
        if (filterVal == "Month" || filterVal == "Week" || filterVal == "Day") {
          revenueDates &&
            revenueDates.length > 0 &&
            revenueDates.forEach((item) => {
              let resData = res?.referralFeeData.find(
                (element) =>
                  element?.created_at.split("T")[0] == dateFormatChange(item)
              );

              if (resData) {
                referral.push(resData?.sumOrder.toFixed(6));
              } else {
                referral.push("0");
              }
            });
          setReferralFeeData(referral);
        } else {
          let monthsData = [];
          getListOfMonth(yearFilter).forEach((item) => {
            let resData = res?.referralFeeData.find(
              (element) => compareMonth(element?.created_at) == item
            );
            if (resData) {
              monthsData.push(resData?.sumOrder.toFixed(6));
            } else {
              monthsData.push("0");
            }
          });
          setReferralFeeData(monthsData);
        }
      }
    });
  };

  useEffect(() => {
    let data = {
      address: getWalletAddress(),
    };
    props.getRevenueFn(data);
  }, []);

  useEffect(() => {
    console.log();
    if (revenueDates && revenueDates.length > 0) {
      getFiltersList(revenueDates);
    }
  }, [revenueDates]);

  const getFiltersList = (datesList) => {
    // console.log("datesList", datesList);
    if (datesList && datesList.length > 0) {
      let startDate = dateFormatChange(datesList[0]);
      let endDate = dateFormatChange(datesList[datesList.length - 1]);
      if (filterVal != "Day") {
        setFromDate(startDate);
        setToDate(endDate);
      }

      getRevnueListData(startDate, endDate);
    }
  };

  const getMonthDateList = (days) => {
    let datesList = getMonthDates(days);
    setRevenueDates(datesList);
  };

  const getDatesList = (week) => {
    let datesList = getWeekDates(week);
    setRevenueDates(datesList);
  };

  const getYearDateList = (years) => {
    let datesList = getYearDates(years);
    setRevenueDates(datesList);
  };

  const getIntitalDay = () => {
    let datesList = getDaysDate(1);
    setRevenueDates(datesList);
  };

  useEffect(() => {
    if (yearFilter && filterVal == "Year") {
      getYearDateList(yearFilter);
    } else if (monthFilter && filterVal == "Month") {
      getMonthDateList(monthFilter);
    } else if (weekFilter && filterVal == "Week") {
      getDatesList(weekFilter);
    } else if (filterVal == "Day") {
      getIntitalDay();
    }
  }, [yearFilter, monthFilter, weekFilter, filterVal]);

  const getDaysFilter = (start, end) => {
    // let startDate = start ? dateFormat(start, "yyyy-mm-dd") : "";
    // let endDate = end ? dateFormat(end, "yyyy-mm-dd") : "";

    if (start && end) {
      let listVal = getDates(start, end);
      setRevenueDates(listVal);

      // getRevnueListData(startDate, endDate);
    }
  };
  const handleDate = (e, type) => {
    if (type == "from") {
      setFromDate(e);
      getDaysFilter(e, toDate);
    } else {
      setToDate(e);
      getDaysFilter(fromDate, e);
    }
  };

  return (
    <Container fluid className="main-content-container px-4 dashbd_page">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Dashboard"
          /* subtitle="Admin" */
          className="text-sm-left mb-2"
        />
      </Row>
      <div className="dashboard_status">
        <Row className="py-4">
          <Col lg={3} md={6} className="d-flex mb-3">
            <Card
              className="w-100"
              style={{ width: "15rem", backgroundColor: "#0047AB" }}
            >
              {/* <Link to="/auth/traders"> */}
              <Card.Body>
                <Card.Title>
                  <h4 className="text-white">TOTAL REVENUE </h4>
                </Card.Title>
                <Card.Text>
                  <h1 className="text-white">
                    {"$"}
                    {revenueList?.totalRevenue > 0
                      ? showTwoDecimal(revenueList?.totalRevenue, 8)
                      : 0}
                  </h1>
                </Card.Text>
              </Card.Body>
              {/* </Link> */}
            </Card>
          </Col>
          <Col lg={3} md={6} className="d-flex mb-3">
            <Card
              className="w-100"
              style={{ width: "15rem", backgroundColor: "rgb(0 171 169)" }}
            >
              {/* <Link to="/auth/traders"> */}
              <Card.Body>
                <Card.Title>
                  <h4 className="text-white">
                    {filterVal.toLocaleUpperCase()} REVENUE{" "}
                  </h4>
                </Card.Title>
                <Card.Text>
                  <h1 className="text-white">
                    {"$"}
                    {filterRevenue && filterRevenue > 0
                      ? showTwoDecimal(filterRevenue, 8)
                      : 0}
                  </h1>
                </Card.Text>
              </Card.Body>
              {/* </Link> */}
            </Card>
          </Col>
        </Row>
      </div>

      <br />

      <div className="graph_area">
        <Row>
          <Col lg={12} md={12} className="mb-3">
            <Card className="w-100 graph_card">
              <Card.Title>
                <h3> REVENUE: </h3>
              </Card.Title>
              <div>
                <div className="box_deco">
                  <Row className="withdrw_srch align-items-end ">
                    <Col lg={4} md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Filter</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue="Status"
                          name="status"
                          value={filterVal}
                          id="status"
                          onChange={(e) => {
                            setFromDate("");
                            setToDate("");
                            setRevenueDates([]);
                            setFilterVal(e.target.value);
                          }}
                        >
                          {REVENUE_FILTER.map((item) => {
                            return (
                              <option
                                selected={filterVal == item.value}
                                value={item.value}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    {filterVal == "Year" && (
                      <Col lg={4} md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>Select Year</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue="Status"
                            name="status"
                            value={yearFilter}
                            id="status"
                            onChange={(e) => setYearFilter(e.target.value)}
                          >
                            {YEAR_FILTER().map((item, i) => {
                              return (
                                <option
                                  key={i}
                                  value={item}
                                  selected={yearFilter == item}
                                >
                                  {item}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )}
                    {/* {filterVal == "Week" && (
                      <Col lg={4} md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>Select Week</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue="Status"
                            name="status"
                            value={weekFilter}
                            id="status"
                            onChange={(e) => setWeekFilter(e.target.value)}
                          >
                            {WEEK_FILTER.map((item) => {
                              return (
                                <option
                                  selected={weekFilter == item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )} */}
                    {/* {filterVal == "Month" && (
                      <Col lg={4} md={6} sm={12}>
                        <Form.Group>
                          <Form.Label>Select Month</Form.Label>
                          <Form.Control
                            as="select"
                            defaultValue="Status"
                            name="status"
                            value={monthFilter}
                            id="mnthFilter"
                            onChange={(e) => setMonthFilter(e.target.value)}
                          >
                            {MONTH_FILTER.map((item) => {
                              return (
                                <option
                                  selected={monthFilter == item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    )} */}

                    {filterVal == "Day" && (
                      <>
                        <Col
                          lg={4}
                          md={6}
                          sm={12}
                          style={{ position: "relative" }}
                          className="mb-3"
                        >
                          <Form.Label>From Date</Form.Label>
                          <DatePicker
                            selected={fromDate ? new Date(fromDate) : ""}
                            placeholderText="MM/DD/YYYY"
                            className="form-control"
                            onChange={(e) => {
                              handleDate(e, "from");
                            }}
                            maxDate={!!toDate ? new Date(toDate) : new Date()}
                          />
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={12}
                          style={{ position: "relative" }}
                          className="mb-3"
                        >
                          <Form.Label>To Date</Form.Label>
                          <DatePicker
                            // disabled={!fromDate}
                            selected={toDate ? new Date(toDate) : ""}
                            placeholderText="MM/DD/YYYY"
                            className="form-control"
                            onChange={(e) => {
                              handleDate(e, "to");
                            }}
                            minDate={new Date(fromDate)}
                            maxDate={new Date()}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
                {/* {console.log(
                  "revenuList",
                  orderFeeData,
                  orderFeeDataMaker,
                  orderFeeDataTaker,
                  referralFeeData
                )} */}

                <RevenueChart
                  orderFeeData={orderFeeData}
                  orderFeeDataMaker={orderFeeDataMaker}
                  orderFeeDataTaker={orderFeeDataTaker}
                  referralFeeData={referralFeeData}
                  revenueDates={
                    filterVal == "Month"
                      ? revenueDates
                      : filterVal == "Week"
                      ? weekDaysName().reverse()
                      : filterVal == "Year"
                      ? getListOfMonth(yearFilter)
                      : filterVal == "Day"
                      ? revenueDates
                      : ""
                  }
                />
                {/* <Line
                  data={dataSetRevenue}
                  className="fix_graph_height"
                  width={700}
                  height={300}
                  options={{
                    title: {
                      display: true,
                      text: "REVENUE",
                      fontSize: 20,
                    },
                    legend: {
                      display: "false",
                      position: "bottom",
                      usePointStyle: "true",
                    },
                  }}
                /> */}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    dashboardCounts: state.dashboard.dashboardCounts,
    revenueList: state.dashboard.revenueList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getDashboardCountsFn: (data) => dispatch(getDashboardCountsFn(data)),
    getRevenueFn: (data) => dispatch(getRevenueFn(data)),
    getRevenueListFn: (data) => dispatch(getRevenueListFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
