import React, { useEffect, useState } from "react";
import { Nav } from "shards-react";
import "./MainSidebar.css";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

import { connect } from "react-redux";

import { subAdminRole } from "../../../Redux/Actions/user/user.action";
import { PERMISSONS } from "../../../Constants/constant";
import { setMenu } from "../../../utils/utils";
import { useParams } from "react-router";

const SidebarNavItems = (props) => {
  const {
    id,
    pairname,
    currencyCode,
    traderId,
    userId,
    pairCoin,
    sellerid,
    offerId,
  } = useParams();

  const [selectedMenuBar, setSelectedMenuBar] = useState([]);
  const [visible, setVisible] = useState(false);
  const [allPaths, setAllPath] = useState(window.location?.pathname);

  // constructor(props) {
  //   super(props);

  // this.state = {
  //   visible: false,
  //   selectedMenuBar: selectedOptions,
  // };
  // if (!localStorage.getItem("localMenu")) setMenu(selectedOptions);
  // }

  // const handleOnchange = (e, type) => {
  //   setVisible({
  //     visible: !visible,
  //   });
  // };

  const getMenu = (e, type) => {
    var selectedOptions = [];

    if (props?.userData?.accessRole == 1 && props.userData?.role) {
      localStorage.setItem("localMenu", "");
      selectedOptions = props.userData?.role.split(",");
    } else if (props?.userData?.accessRole == 0) {
      localStorage.setItem("localMenu", "");
      PERMISSONS.forEach((element) => {
        selectedOptions.push(element.name);
      });
    }
    if (!localStorage.getItem("localMenu")) setMenu(selectedOptions);
    setSelectedMenuBar(selectedOptions);
  };

  useEffect(() => {
    getMenu();
  }, [props?.userData]);

  useEffect(() => {
    setAllPath(window.location?.pathname);
    let localMenu = localStorage.getItem("localMenu");
    let subPaths = allPaths.split("/");
    let checkPath = allPaths.includes("dashboard")
      ? subPaths[3]
      : allPaths.includes("admin")
      ? subPaths[3]
      : subPaths[2];
    if (
      checkPath != "pageNotFound" &&
      !localMenu?.includes(checkPath) &&
      props?.userData?.accessRole == 1
    ) {
      window.location.href = "/admin/auth/dashboard";
    }
  }, [
    window.location?.pathname,
    visible,
    id,
    pairname,
    currencyCode,
    traderId,
    userId,
    pairCoin,
    sellerid,
    offerId,
  ]);

  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        <NavItem>
          <NavLink
            onClick={() => setVisible(!visible)}
            tag={RouteNavLink}
            to="/auth/dashboard"
          >
            <span>Dashboard</span>
          </NavLink>
        </NavItem>
        {/* 
        {props.userData?.role &&
          selectedMenuBar.includes("manage_subadmin") && (
            <NavItem>
              <NavLink
                className={
                  allPaths.includes("/sub-admin-management/sub-admin-edit/") ||
                  allPaths.includes("/sub-admin-management/sub-admin-users")
                    ? `active`
                    : ""
                }
                onClick={() => setVisible(!visible)}
                tag={RouteNavLink}
                to="/auth/sub-admin-management/sub-admin-users"
              >
                <span>Sub Admin</span>
              </NavLink>
            </NavItem>
          )} */}

        <NavItem>
          <NavLink
            onClick={() => setVisible(!visible)}
            tag={RouteNavLink}
            to="/auth/eterna-fee"
          >
            <span>Eterna Fee</span>
          </NavLink>
        </NavItem>
      </Nav>
      <></>
    </div>
  );
};

//export default SidebarNavItems;
const mapStateToProps = (state) => {
  return {
    userData: state.auth.encryptUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    subAdminRole: () => dispatch(subAdminRole()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
