import { fetch } from "../axios.service";
import {
  BASE_URL,
  TRADING_BASE_URL,
  WALLET_BASE_URL,
  ORDERS_BASE_URL,
} from "../../Constants/constant";

// export const getDashboardCountsApi = (options) => {
//   return fetch("get", `${BASE_URL}/admin/dashboard/counts`, {}, options);
// };

export const getRevenue = (data, options) => {
  return fetch("post", `${BASE_URL}/socket/admin/revenue`, data, options);
};

export const getRevenueList = (data) => {
  console.log("getRevenueList", data);
  return fetch("get", `${BASE_URL}/socket/admin/revenue_list`, {}, {}, data);
};

// export const getActiveUsersApi = (options) => {
//   return fetch("get", `${ORDERS_BASE_URL}/getActiveUsers`, {}, options);
// };

// export const tradeVolumeApi = (options) => {
//   return fetch("get", `${ORDERS_BASE_URL}/trades_volume`, {}, options);
// };

// export const tradeByMonthApi = (options) => {
//   return fetch("get", `${TRADING_BASE_URL}/trade/tradesByMonth`, {}, options);
// };

// export const activeCoinsApi = (options) => {
//   return fetch("get", `${WALLET_BASE_URL}/active-coins`, {}, options);
// };

/* export const dashboardApi = (data, options) => {
  return fetch("get", `${BANKING_BASE_URL}/admin/dashboardDataBanking`, data, options);
};
 */
