import React, { Component, lazy, Suspense } from "react";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { rootName, BASEURL } from "../Constants/constant";
import { connect } from "react-redux";

import Dashboard from "../Components/pages/Dashboard";
import PageNotFound from "../Components/pages/PageNotFound/PageNotFound";
import EternaFee from "../Components/pages/FeeManagement/EternaFee";

class PrivateRoutes extends Component {
  constructor() {
    super();
    let dynmRoutes = [];
    // dynmRoutes["traders"] = Traders;

    this.state = {
      dynmRoutes: dynmRoutes,
      alottedTabsData: [],
    };
  }

  render() {
    let { userInfo, allTabsData } = this.props;

    return (
      <div className="PrivateArea__content">
        <Switch>
          <Route
            path={`${BASEURL}/auth/dashboard`}
            component={Dashboard}
            exact={true}
          />
          <Route
            path={`${BASEURL}/auth/eterna-fee`}
            component={EternaFee}
            exact={true}
          />

          <Route path="*" component={PageNotFound} exact={true} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.encryptUserInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoutes));
