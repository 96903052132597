import React from "react";
import { Container, Row } from "react-bootstrap";

import "./PageNotFound.scss";

export default function PageNotFound(props) {
  return (
    <Container fluid className="error_sec">
      <Container>
        <Row>
          <div className="error_info text-left">
            <h1>404!</h1>
            <h3>UH OH! You are lost.</h3>
            <p>
              The page you are looking for does not exist. How you got here is a
              mystery. But you can click the button below to go back to the
              homepage.
            </p>
            <button onClick={()=>props.history.push("/")} buttontext="Back" className="error_btn">Back</button>
          </div>
        </Row>
      </Container>
    </Container>
  );
}
