import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";

import { BASEURL, rootName } from "../Constants/constant";
import { Provider } from "react-redux";
import configureStore from "../Redux/Reducers/store";
import { ConnectedRouter } from "connected-react-router";

import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import { createBrowserHistory } from "history";

import AuthGuard from "./Gurads/AuthGuard.guard";
import NoGuard from "./Gurads/NoGuard";

import PublicRoutes from "./publicRoutes";
import PrivateRoutes from "./privateRoutes";

import LoaderComponent from "../Components/Loader/loader.component";

let history = createBrowserHistory();
let { store, persistor } = configureStore();

export default class Routing extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <LoaderComponent />

            <Router>
              <Switch>
                <AuthGuard path={`/auth`} component={PrivateRoutes} />
                <NoGuard path={`/`} component={PublicRoutes} />
                {/* <Route path="*" component={PageNotFound} /> */}
              </Switch>
            </Router>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}
