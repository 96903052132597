import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Dropdown, Card } from "react-bootstrap";
import PageTitle from "../../common/PageTitle";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import "../../../App.css";

import { useForm } from "react-hook-form";
import _ from "lodash/fp";

import {
  getSingleSubadminFn,
  updateSubadminFn,
} from "../../../Redux/Actions/subadmin/subadmin.action";
import { PERMISSONS } from "../../../Constants/constant";
import showPassword from "../../../shards-dashboard/images/eyeOpen.svg";
import HidePassword from "../../../shards-dashboard/images/eyeClose.svg";

function EditSubadmin(props) {
  const [permission, setPermission] = useState([]);
  const [allotedPermission, setallotedPermission] = useState([]);
  const [email, setEmail] = useState("");
  const formRef = useRef(null);
  const [showPasswords, setShowPassword] = useState(false);
  const [showPasswordsC, setShowPasswordC] = useState(false);

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const handleReset = () => {
    formRef.current.reset();
    // setValidated(false);
  };

  useEffect(() => {
    let id = props.match.params.id;
    let data = {
      id: id,
    };
    props.getSingleSubadminFn(data);
  }, []);

  useEffect(() => {
    handleReset();
    setValue("email", props.singleSubadminData?.email);
    setEmail(props.singleSubadminData?.email);
    let allotedPermissions = props.singleSubadminData?.role;

    // console.log('dataallowed',removeDuplicates(allotedPermissions));

    let permissionArray = allotedPermissions?.split(",");

    //   console.log(removeDuplicates(permissionArray));
    permissionArray !== undefined &&
      permissionArray.map((dta) => {
        setPermission((oldData) => [...oldData, dta]);
      });
    setallotedPermission(permissionArray);
  }, [props.singleSubadminData]);

  const password = useRef({});
  password.current = watch("password", "");

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }
  const onSubmit = (data) => {
    let { singleSubadminData } = props;
    delete data.confirmPassword;
    data.role = removeDuplicates(permission);
    //data.email = singleSubadminData?.email
    data.accessRole = singleSubadminData?.access_role;
    data.id = singleSubadminData?.admin_users_id;
    data.status = singleSubadminData?.status;
    props
      .updateSubadminFn(data)
      .then((res) => {
        handleReset();
        props.history.push("/auth/sub-admin-management/sub-admin-users");
      })
      .catch((err) => console.log(err));
  };

  let permissionData = [];
  const getPermission = (e) => {
    permissionData.push(e.target.checked ? e.target.name : "");

    permission.map((perDta, i) => {
      if (perDta === e.target.name && !e.target.checked) {
        permission.splice(i, 1);
      }
    });
    if (e.target.checked) {
      setPermission((oldData) => [...oldData, e.target.name]);
    }
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <Col lg={12}>
            <PageTitle
              title="Sub Admin Management"
              subtitle=""
              className="text-sm-left mb-3"
            />
          </Col>
        </Row>

        <Card>
          <Card.Header></Card.Header>
          <Card.Body>
            <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <input
                      value={props?.singleSubadminData?.email}
                      id="email"
                      type="email"
                      name="email"
                      disabled={true}
                      ref={register({
                        //required: true,
                        //maxLength: 20,
                      })}
                      className="form-control"
                    />

                    {/*  {_.get("email.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )} */}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      // type="password"
                      type={showPasswords ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="New Password"
                      ref={register({
                        //validation_messages.password_required,
                        pattern:
                          /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                        message:
                          "Password must contain at least 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be minium 8 characters.",
                      })}
                    />
                    <a
                      className="showPassword1"
                      onClick={() => setShowPassword(!showPasswords)}
                    >
                      <img src={showPasswords ? showPassword : HidePassword} />
                    </a>
                    {_.get("password.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                    {errors.password && (
                      <p style={{ color: "red" }}>
                        Password must contain at least 1 small-case letter, 1
                        Capital letter, 1 digit, 1 special character and the
                        length should be minium 8 characters.
                      </p>
                    )}

                    {/*  {errors.password && <p>{errors.password.message}</p>} */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      // type="password"
                      type={showPasswordsC ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      ref={register({
                        required: password.current ? true : false,

                        validate: (value) =>
                          value === password.current ||
                          "Confirm Password do not match",

                        //pattern: /^[A-Za-z]+$/i
                      })}
                    />
                    <a
                      className="showPassword1"
                      onClick={() => setShowPasswordC(!showPasswordsC)}
                    >
                      <img src={showPasswordsC ? showPassword : HidePassword} />
                    </a>

                    {_.get("confirmPassword.type", errors) === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}

                    {errors.confirmPassword && (
                      <p style={{ color: "red" }}>
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Permission</Form.Label>

                    <div key={`default-checkbox`} className="mb-3">
                      {PERMISSONS.map((permissionDta, index) => {
                        if (permissionDta.name != "manage_subadmin") {
                          return (
                            <Form.Check
                              type="checkbox"
                              id={`default-checkbox-${index}`}
                              label={permissionDta.label}
                              name={permissionDta.name}
                              onClick={getPermission}
                              defaultChecked={
                                allotedPermission != undefined &&
                                allotedPermission.find((x) => {
                                  return x === permissionDta.name
                                    ? true
                                    : false;
                                })
                              }
                              //ref={register}
                            />
                          );
                        }
                      })}
                    </div>
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>

              <Button variant="primary" type="submit">
                {" "}
                Update{" "}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    //pairListData: state.orders.pairListData,
    singleSubadminData: state.subadmin.singleSubadminData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getPairListFn: (data) => dispatch(getPairListFn(data))
    getSingleSubadminFn: (data) => dispatch(getSingleSubadminFn(data)),
    updateSubadminFn: (data) => dispatch(updateSubadminFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubadmin);
