import React, { useState } from "react";
import { Row, Col } from "shards-react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import _ from "lodash/fp";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import HidePassword from "../profile/images/eye_close.png";
import showPassword from "../profile/images/eye_open.png";
import "./loginForm.css";
import Logo from "../../../images/user-profile/logo.svg";

import { useDispatch, useSelector } from "react-redux";

function Login(props) {
  const { register, handleSubmit, watch, errors } = useForm();
  const [showpassword, setshowpassword] = useState(false);

  let showmodal = true;

  return (
    <div>
      <div className="Login log_des">
        {/* <loaderComponent></loaderComponent> */}
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <img src={Logo} />
        </div>
        <Modal
          show={/* this.state. */ showmodal}
          className="login_bg"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="py-4">
            <Modal.Title className="w-100" id="contained-modal-title-vcenter">
              <h3 className="text-center"> Admin Login </h3>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="pb-4">
            <Row className="justify-content-center">
              <Col sm={11}>
                <button
                  onClick={() => {
                    props.connectWallet();
                  }}
                >
                  Connect Wallet
                </button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Login;
