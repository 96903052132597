import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { TurnedIn } from "@material-ui/icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Chart.js Line Chart",
//     },
//   },
// };

const options = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        },
      },
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
        },
        gridLines: {
          // color: "#fff",
          display: true,
        },
        ticks: {
          display: true, // it should work
        },
      },
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
        },
        gridLines: {
          // color: "#fff",
          display: true,
        },
        ticks: {
          display: true, // it should work
        },
      },
    ],
  },
  legend: {
    display: true,
  },
};

export default function RevenueChart(props) {
  const {
    orderFeeData,
    orderFeeDataMaker,
    orderFeeDataTaker,
    referralFeeData,
    revenueDates,
  } = props;
  const labels = revenueDates;
  const data = {
    labels,
    datasets: [
      {
        label: "Fee",
        data: orderFeeData,
        borderColor: "#007bff",
        backgroundColor: "#4480c1",
      },
      {
        label: "Maker",
        data: orderFeeDataMaker,
        borderColor: "#008000",
        backgroundColor: "#37ab37",
      },
      {
        label: "Taker",
        data: orderFeeDataTaker,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Referral",
        data: referralFeeData,
        borderColor: "#450fcee3",
        backgroundColor: "#6143aee3",
      },
    ],
  };

  return <Line height={100} options={options} data={data} />;
}
