import React from "react";
import { Link } from "react-router-dom";
import { hashHistory } from "react-router";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import { Modal, Button } from "react-bootstrap";
import BWBLogo from "../../../../images/user-profile/bwb_logo.svg";
import { connect } from "react-redux";
import {
  logoutUser,
  getAdminDetails,
} from "../../../../Redux/Actions/user/user.action";
import "../../../../App.css";
import "./NavbarNav.css";
import { Icon } from "semantic-ui-react";
import { getWalletAddressFormate } from "../../../../Constants/constant";

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      show: false,
    };
    this.toggle = this.toggle.bind(this);

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.logouthandleUser = this.logouthandleUser.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  logoutUserPopup = () => {
    this.setState({
      show: true,
    });
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  logouthandleUser = () => {
    let { logoutUser } = this.props;
    logoutUser();
  };
  toggle() {
    this.setState((prevState) => {
      return { visible: !prevState.visible };
    });
  }

  componentDidMount = () => {
    // this.props.getAdminDetails();
  };
  render() {
    return (
      <>
        <NavItem
          tag={Dropdown}
          caret
          toggle={this.toggleUserActions}
          style={{ cursor: "pointer" }}
        >
          <Dropdown open={this.state.visible} toggle={this.toggle}>
            <DropdownToggle
              caret
              tag={NavLink}
              className="text-nowrap px-3 pt-3 mt-1"
            >
              {/* <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/dummy.png")}
            alt="User Avatar"
          />{" "} */}
              <span
                className="d-md-inline-block "
                style={{ cursor: "pointer" }}
              >
                Welcome{" "}
                {localStorage.getItem("wallet_address")
                  ? getWalletAddressFormate(
                      localStorage.getItem("wallet_address")
                    )
                  : "Connect Wallet"}
              </span>
            </DropdownToggle>

            <Collapse tag={DropdownMenu} right small>
              {/* <DropdownItem tag={Link}>
                <Link to={"/auth/admin-dashboard-section/admin-profile"}>
                  <Icon name="profile" /> Profile
                </Link>
              </DropdownItem> */}

              {/* <DropdownItem tag={Link} >
          <Icon name="setting"/>  Settings 
          </DropdownItem> */}

              <DropdownItem divider />
              <DropdownItem
                // tag={Link}
                /* to="/" */ onClick={this.logoutUserPopup}
                className="text-danger"
              >
                <Icon name="log out" /> Logout
              </DropdownItem>
            </Collapse>
          </Dropdown>
        </NavItem>
        {this.state.show && (
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            keyboard={false}
            className="logoutModal"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title>Modal title</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>Are You Sure Want to Logout</Modal.Body>
            <Modal.Footer className="mx-auto">
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                tag={Link}
                onClick={this.logouthandleUser}
              >
                Logout
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user?.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    getAdminDetails: () => dispatch(getAdminDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
