export const START_LOADER = "[LOADER] START LOADER";
export const STOP_LOADER = "[LOADER] STOP LOADER";

export const SIGN_IN = "[AUTH] SIGN_IN";
export const TEMP_SIGN_IN = "[AUTH] TEMP_SIGN_IN";
export const SIGN_OUT = "[AUTH] SIGN_OUT";
export const TWO_FA_AUTHENTICATE = "TWO_FA_AUTHENTICATE";
export const DEVICE_AUTHENTICATE = "DEVICE_AUTHENTICATE";
export const USERS_LIST = "USERS_LIST";
export const ACTIVE_USERS = "ACTIVE_USERS";

export const DASHBOARD_COUNTS = "DASHBOARD_COUNTS";
export const GET_USER = "GET_USER";
export const GET_ACTIVE_USER = "GET_ACTIVE_USER";
export const TRADE_VOLUME = "TRADE_VOLUME";
export const TRADE_BY_MONTH = "TRADE_BY_MONTH";
export const ACTIVE_COINS = "ACTIVE_COINS";

export const STATISTICS_DATA = "STATISTICS_DATA";
export const DOWNLINE = "DOWNLINE";
export const SINGLE_TRADER_DATA = "SINGLE_TRADER_DATA";
export const USER_WITHDRAD_TRANS = "USER_WITHDRAD_TRANS";

export const ETH_BALANCE = "ETH_BALANCE";
export const BTC_BALANCE = "BTC_BALANCE";
export const BCH_BALANCE = "BCH_BALANCE";
export const USDT_BALANCE = "USDT_BALANCE";
export const USER_GET_BALANCE = "USER_GET_BALANCE";
export const FXT_BALANCE = "FXT_BALANCE";

export const SUBMITTED_KYC_DATA = "SUBMITTED_KYC_DATA";
export const SINGLE_USER_KYC = "SINGLE_USER_KYC";
export const KYC_COUNT = "KYC_COUNT";

export const DEPOSITE_TRANS = "DEPOSITE_TRANS";
export const TRADE_SUMMARY = "TRADE_SUMMARY";
export const CURRENCY_DATA = "CURRENCY_DATA";
export const CURRENCY_PAIR_DATA = "CURRENCY_PAIR_DATA";
export const CURRENCY_AUTH = "CURRENCY_AUTH";
export const CURRENCY_PAIR_AUTH = "CURRENCY_PAIR_AUTH";

export const WITHDRAW_LIST = "WITHDRAW_LIST";
export const WITHDRAW_AUTH = "WITHDRAW_AUTH";

export const SUBADMIN_LIST = "SUBADMIN_LIST";
export const SINGLE_SUBADMIN_DATA = "SINGLE_SUBADMIN_DATA";
export const TWO_FASTATUS = "TWO_FASTATUS";
export const GOOGLE_AUTH_DATA = "GOOGLE_AUTH_DATA";
export const ORDER_LISTING = "ORDER_LISTING";
export const PERMISSION_LISTING = "PERMISSION_LISTING";

//Admin Currencies
export const ADMIN_CURRENCY = "ADMIN_CURRENCY";
export const ESCROW_BALANCE = "ESCROW_BALANCE";
export const SINGLE_CURRENCY = "SINGLE_CURRENCY";

//Currency Pair
export const GET_ALL_CURRECNCY_PAIR = "GET_ALL_CURRECNCY_PAIR";
export const GET_SINGLE_PAIR_DATA = "GET_SINGLE_PAIR_DATA";

//Admin Dispute

export const GET_ADMIN_DISPUTE_LIST = "GET_ADMIN_DISPUTE_LIST";
export const GET_SINGLE_DISPUTE = "GET_SINGLE_DISPUTE";
///Trade LIst
export const GET_ALL_TRADE_LIST = "GET_ALL_TRADE_LIST";
export const GET_SINGLE_TRADE_LIST = "GET_SINGLE_TRADE_LIST";
export const GET_SINGLE_SELLER_RELEASE = "GET_SINGLE_SELLER_RELEASE";
export const GET_SINGLE_OFFER = "GET_SINGLE_OFFER";
//OFFER LIST
export const GET_ALL_OFFER = "GET_ALL_OFFER";
// show && hide sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";

export const SAVE_LOANS = "SAVE_LOANS";
export const SAVE_CASH_LOANS = "SAVE_CASH_LOANS";

export const GET_STABLECOIN_DATA = "GET_STABLECOIN_DATA";
export const SAVE_STABLECOIN_DATA = "SAVE_STABLECOIN_DATA";

export const SAVE_ACQUIRE = "SAVE_ACQUIRE";

export const SAVE_REDEEMLIST = "SAVE_REDEEMLIST";

export const SAVE_ACQUIRELIST = "SAVE_ACQUIRELIST";
export const SAVE_SELECTED_PAIR = "SAVE_SELECTED_PAIR";
export const COUNTRIES_DATA = "COUNTRIES_DATA";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const ADMIN_ROLE = "ADMIN_ROLE";

// swap history

export const SAVE_SWAPHISTORY_LIST = "SAVE_SWAPHISTORY_LIST";

export const SAVE_COUNTRY_LIST = "SAVE_COUNTRY_LIST";
export const SAVE_STATE_LIST = "SAVE_STATE_LIST";
export const SAVE_CITY_LIST = "SAVE_CITY_LIST";

export const STABLE_COIN_HISTORY = "STABLE_COIN_HISTORY";

//NEW DATA

export const FEE_LIST = "FEE_LIST";
export const GET_REVENUE_LIST = "GET_REVENUE_LIST";
