import * as userService from "../../../Services/API/orders.service";
import { toast } from "../../../Components/Toast/toast.component";
import * as types from "../../../Constants/_Types/types.reduxStore";
import { startLoader, stopLoader } from "../Loader/loader.action";
import { BASEURL, SUCCESS_MESSAGE } from "../../../Constants/constant";
//import { reset } from 'redux-form';

const getFeeList = (feeListData) => {
  return {
    type: types.FEE_LIST,
    payload: {
      feeListData: feeListData,
    },
  };
};
// const getOrderListing = (orderListing) => {
//   return {
//     type: types.ORDER_LISTING,
//     payload: {
//       orderListData: orderListing,
//     },
//   };
// };
// export const getTradeSummary = (tradeSummaryData) => {
//   return {
//     type: types.TRADE_SUMMARY,
//     payload: {
//       tradeSummaryData: tradeSummaryData,
//     },
//   };
// };

// export function setTradeData(data) {
//   return (dispatch, getState) => {
//     dispatch(getTradeSummary(data));
//   };
// }

export function getFeeListFn(params) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // let tradeData = [];

    dispatch(startLoader());
    return userService
      .getFeeListApi(params, options)
      .then((res) => {
        console.log("getFeeList", res);
        dispatch(getFeeList(res.data?.data));
        dispatch(stopLoader());
        return res?.data?.data[0];
      })
      .catch((error) => {
        // toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function updateFeeListFn(params) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // let tradeData = [];

    dispatch(startLoader());
    return userService
      .updateFeeListApi(params, options)
      .then((res) => {
        console.log("updateFeeListFn", res);
        toast.success(res?.data?.message);
        dispatch(stopLoader());
        return res?.data?.data[0];
      })
      .catch((error) => {
        console.log("errorerror", error);

        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

export function addFeeListFn(params) {
  return (dispatch, getState) => {
    let options = {
      "api-access-token": getState().auth.tokens,
    };
    // let tradeData = [];

    dispatch(startLoader());
    return userService
      .addFeeListApi(params, options)
      .then((res) => {
        console.log("addFeeListFn", res);
        // dispatch(getFeeList(res.data?.data));
        dispatch(stopLoader());
        toast.success(res?.data?.message);
        return res?.data?.data[0];
      })
      .catch((error) => {
        console.log("errorerror", error);
        toast.error(error?.data?.message);
        dispatch(stopLoader());
      });
  };
}

// export function getPairListFn(history) {
//   return (dispatch, getState) => {
//     let options = {
//       "api-access-token": getState().auth.tokens,
//     };

//     dispatch(startLoader());
//     return userService
//       .getPairListApi(options)
//       .then((res) => {
//         dispatch(getPairList(res.data?.data));
//         dispatch(stopLoader());
//         return res.data.data;
//       })
//       .catch((error) => {
//         toast.error(error?.data?.message);
//         dispatch(stopLoader());
//       });
//   };
// }
// export function depositeFilterDataFn(filterData) {
//   return (dispatch, getState) => {
//     let options = {
//       "api-access-token": getState().auth.tokens,
//     };
//     dispatch(startLoader());
//     return userService
//       .getorderListPair(filterData, options)
//       .then((res) => {
//         dispatch(getOrderListing(res.data?.data));
//         dispatch(stopLoader());
//         return res.data.data;
//       })
//       .catch((error) => {
//         toast.error(error?.data?.message);
//         dispatch(stopLoader());
//       });
//   };
// }

// export function csvDataFn(filterData) {
//   return (dispatch, getState) => {
//     let options = {
//       "api-access-token": getState().auth.tokens,
//     };
//     dispatch(startLoader());
//     return userService
//       .getorderListPair(filterData, options)
//       .then((res) => {
//         // dispatch(getOrderListing(res.data?.data));
//         dispatch(stopLoader());
//         return res.data.data;
//       })
//       .catch((error) => {
//         toast.error(error?.data?.message);
//         dispatch(stopLoader());
//       });
//   };
// }
