import { fetch } from "../axios.service";
import { BASE_URL } from "../../Constants/constant";

// export const getPairListApi = (options) => {
//   return fetch("get", `${BASE_URL}/pair_list`, {}, options);
// };

export const getFeeListApi = (params, options) => {
  return fetch("post", `${BASE_URL}/socket/admin/get_fees`, params, options);
};
export const updateFeeListApi = (params, options) => {
  return fetch("post", `${BASE_URL}/socket/admin/update_fee`, params, options);
};

export const addFeeListApi = (params, options) => {
  return fetch("post", `${BASE_URL}/socket/admin/add_fee`, params, options);
};

// export const getorderListPair = (filterseletedData, options) => {
//   Object.keys(filterseletedData).forEach((key) => {
//     if (filterseletedData[key] === "" || filterseletedData[key] === "all") {
//       delete filterseletedData[key];
//     }
//   });
//   let params = filterseletedData;
//   return fetch("get", `${BASE_URL}/order_list`, {}, options, params);
// };
