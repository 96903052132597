import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Table, Form, Button, Modal, Card } from "react-bootstrap";
import { Pagination, Icon } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import PageTitle from "../../common/PageTitle";
import dateFormat from "dateformat";

import {
  getFeeListFn,
  updateFeeListFn,
  addFeeListFn,
} from "../../../Redux/Actions/orders/orders.action";

import DatePicker from "react-datepicker";

import { connect } from "react-redux";
import "moment/locale/en-gb";
import "rc-datepicker/lib/style.css";
import {
  offset,
  limit,
  ORDER_STATUS,
  ORDER_TYPE,
  PAGELIMIT,
  smallestUnit,
  getWalletAddress,
} from "../../../Constants/constant";
import queryString from "query-string";
//import "./Users.css";
import "../../../App.css";

import ImportCsv from "../../common/common";
import { convertTZ } from "../../../utils/utils";
import { toast } from "../../Toast/toast.component";

const EternaFee = (props) => {
  let { feeListData, totalItems, getFeeListFn, addFeeListFn, updateFeeListFn } =
    props;

  const [page, setPage] = useState(1);
  const [checkError, setCheckError] = useState(false);
  const [showModel, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    getListFee();
  }, []);

  const getListFee = () => {
    let params = {
      address: localStorage.getItem("wallet_address"),
    };
    getFeeListFn(params);
  };

  // filter end
  const getPageLimit = (e) => {
    setTimeout(() => {
      // searchValues(1);
    });
  };
  const handleClose = () => {
    setShowModal(false);
    setDeletePopup(false);
    setCurrentData({});
  };
  const handleModal = (data) => {
    setShowModal(true);
    setCurrentData(data);
  };

  const onConfirmation = () => {
    if (currentData?.key_id) {
      if (currentData?.value && Number(currentData?.value) > -1) {
        let newVal = currentData?.value
          ? currentData?.value.includes(".")
            ? currentData?.value.split(".")
            : currentData?.value
          : currentData?.value;
        let valNumber = currentData?.value;
        if (newVal[0] == "") {
          valNumber = 0 + "." + newVal[1];
        }
        setCheckError(false);

        let prama = {
          address: getWalletAddress(),
          value: valNumber,
          keyId: currentData?.key_id,
          subKeyId: currentData?.sub_key_id,
        };
        updateFeeListFn(prama)
          .then(() => {
            setCurrentData({});
            setShowModal(false);
            getListFee();
          })
          .catch((error) => {
            console.log(error);
            setCurrentData({});
            setShowModal(false);
          });
      } else {
        setCheckError(true);
      }
    } else {
      if (
        currentData?.key_nam &&
        currentData?.sub_key_name &&
        currentData?.value
      ) {
        setCheckError(false);
        let prama = {
          address: getWalletAddress(),
          keyName: currentData?.key_name,
          subKeyName: currentData?.sub_key_name,
          value: currentData?.value,
        };

        addFeeListFn(prama)
          .then(() => {
            setCurrentData({});
            setShowModal(false);
            getListFee();
          })
          .catch((error) => {
            console.log(error);
            setCurrentData({});
            setShowModal(false);
          });
      } else {
        setCheckError(true);
        toast.error("All fields are required!");
      }
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    let newData = {};

    newData[name] = value;

    setCurrentData({ ...currentData, ...newData });
  };
  // let { enableFilterInput } = state;
  // let { filterData } = state;
  function preciseTodecimal(num) {
    return num / smallestUnit != 0 ? (num / smallestUnit).toFixed(8) : 0;
  }
  // alert(totalRecords);

  const handleDelete = (data) => {
    setCurrentData(data);
    setDeletePopup(true);
  };

  const onHandleDelete = () => {};

  return (
    <div>
      {deletePopup && (
        <Modal
          show={deletePopup}
          onHide={handleClose}
          keyboard={false}
          className="editModal"
        >
          <Modal.Header closeButton>
            <h3>Delete Fee</h3>
          </Modal.Header>
          <Modal.Body>Are you sure want to delete fee?</Modal.Body>
          <Modal.Footer className="mx-auto">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="button"
              // disabled={getDisapproveDisableStatus()}
              variant="primary"
              // tag={Link}
              onClick={() => onHandleDelete()}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showModel && (
        <Modal
          show={showModel}
          onHide={handleClose}
          keyboard={false}
          className="editModal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h3>{currentData?.key_id ? "Edit" : "Add"} Fee</h3>
          </Modal.Body>
          <Modal.Footer className="mx-auto">
            {!currentData?.key_id && (
              <>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group className="mb-0">
                    <Form.Label>Kye Name</Form.Label>
                    <Form.Control
                      // as="textarea"
                      name="key_name"
                      id="keyName"
                      placeholder="Enter Key Name"
                      value={currentData?.key_name}
                      // maxLength="150"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    {checkError && !currentData?.key_name ? (
                      <p className="errorMsg">This field is required</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group className="mb-0">
                    <Form.Label>Sub Key Name</Form.Label>
                    <Form.Control
                      // as="textarea"
                      name="sub_key_name"
                      id="subKeyName"
                      placeholder="Enter Sub Key"
                      value={currentData?.sub_key_name}
                      // maxLength="150"
                      onChange={(e) => handleChange(e)}
                      // style={{ height: "100px" }}
                    />
                    {checkError && !currentData?.sub_key_name ? (
                      <p className="errorMsg">This field is required</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Form.Group className="mb-0">
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      // as="textarea"
                      name="type"
                      id="type"
                      placeholder="Enter type"
                      value={currentData?.type}
                      // maxLength="150"
                      onChange={(e) => handleChange(e)}
                      // style={{ height: "100px" }}
                    />
                    {checkError && !currentData?.type ? (
                      <p className="errorMsg">This field is required</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
              </>
            )}
            <Col lg={12} md={12} sm={12}>
              <Form.Group className="mb-0">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  // as="textarea"
                  name="value"
                  id="value"
                  placeholder="Enter Value"
                  value={currentData?.value ? currentData?.value : ""}
                  // maxLength="150"
                  onChange={(e) => {
                    let validNumber = new RegExp(/^\d*\.?\d*$/);
                    let val = e.target.value;
                    if (validNumber.test(val)) {
                      handleChange(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                />

                {checkError && !currentData?.value ? (
                  <p className="errorMsg">This field is required</p>
                ) : checkError && currentData?.value < 0 ? (
                  <p className="errorMsg">Value must be greater then 0</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>

            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="button"
              // disabled={getDisapproveDisableStatus()}
              variant="primary"
              // tag={Link}
              onClick={() => onConfirmation()}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col lg={12}>
            <PageTitle
              title="Eterna Fee"
              subtitle=""
              className="text-sm-left mb-3"
            />
          </Col>
        </Row>
        <div className="box_deco">
          <Row className="withdrw_tran">
            <Col lg={4} md={6} sm={12}>
              <Button
                variant="primary"
                className="ml-2"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Fee
              </Button>
            </Col>
          </Row>
        </div>
        <div className="box_deco">
          <Row className="justify-content-end no-gutters">
            {feeListData != undefined && feeListData.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <td className="pl-3 text-nowrap">S No.</td>
                    <th className="pl-3 text-nowrap">Name</th>
                    <th className="pl-3 text-nowrap">Sub Key Name</th>
                    <th className="pl-3 text-nowrap">Type</th>
                    <th className="pl-3 text-nowrap">Value</th>
                    <th className="pl-3 text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feeListData.map((item, index) => {
                    let sno =
                      page == 1 ? index + 1 : (page - 1) * limit + index + 1;
                    return (
                      <tr>
                        <td className="pl-3 text-nowrap">{sno}</td>
                        <td className="pl-3 text-nowrap">{item?.key_name}</td>
                        <td className="pl-3 text-nowrap">
                          {item?.sub_key_name}
                        </td>
                        <td className="pl-3 text-nowrap">{item.type}</td>
                        <td className="pl-3 text-nowrap">{item.value}</td>
                        <td className="pl-6 text-nowrap ">
                          <td className="pl-3 text-nowrap">
                            <Button
                              type="button"
                              onClick={() => handleModal(item)}
                            >
                              {" "}
                              Edit
                            </Button>
                          </td>
                          {/* <td className="pl-3 text-nowrap">
                            <Button
                              type="button"
                              onClick={() => handleDelete(item)}
                            >
                              {" "}
                              Delete
                            </Button>
                          </td> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <td className="pl-3 text-nowrap">S No.</td>
                    <th className="pl-3 text-nowrap">Name</th>
                    <th className="pl-3 text-nowrap">Sub Key Name</th>
                    <th className="pl-3 text-nowrap">Type</th>
                    <th className="pl-3 text-nowrap">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <td colSpan="5">No Record Found</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {feeListData?.totalItems != undefined &&
              feeListData?.totalItems > limit && (
                <Pagination
                  defaultActivePage={5}
                  // onPageChange={pageChange}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                  }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                  }}
                  totalPages={Math.ceil(feeListData?.totalItems / limit)}
                />
              )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    feeListData: state.orders.feeListData,
    totalItems: state.orders.feeListData?.totalItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeeListFn: (data) => dispatch(getFeeListFn(data)),
    updateFeeListFn: (data) => dispatch(updateFeeListFn(data)),
    addFeeListFn: (data) => dispatch(addFeeListFn(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EternaFee);
